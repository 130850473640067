<template>
  <div class="mapBox">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="padding: 40px 60px"
    >
      <el-form-item :label="$t('DeviceManage.Geofence.Name')+'：'" prop="name">
        <el-input
          v-model.trim="ruleForm.name"
          size="small"
          style="width: 240px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Geofence.Longitude')+'：'" prop="longitude">
        <el-input
          v-model="ruleForm.longitude"
          size="small"
          style="width: 240px"
          :placeholder="$t('DeviceManage.Geofence.Character')"
          @blur="changeRadius"
          :disabled="ruleForm.shape == 1"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Geofence.Latitude')+'：'" prop="latitude">
        <el-input
          v-model="ruleForm.latitude"
          size="small"
          style="width: 240px"
          :placeholder="$t('DeviceManage.Geofence.Character')"
          @blur="changeRadius"
          :disabled="ruleForm.shape == 1"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Geofence.Graphical')+'：'">
        <el-select
          v-model="ruleForm.shape"
          size="small"
          style="width: 240px"
          :placeholder="$t('DeviceManage.Geofence.Selectgraphic')"
        >
          <el-option
            v-for="(item, index) in graphical"
            :key="index"
            :label="$t(`DeviceManage.Geofence.Graph.${item.label}`)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Geofence.Radius')+'：'" prop="radius" v-if="ruleForm.shape == 0">
        <el-input
          v-model="ruleForm.radius"
          size="small"
          style="width: 240px"
          maxlength="7"
          :placeholder="$t('DeviceManage.Geofence.Inputcharacte')"
          @input="changeRadius"
        ></el-input
        >&nbsp;{{$t('DeviceManage.Geofence.Meter')}} 
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Geofence.Length')+'：'" prop="length" v-if="ruleForm.shape == 1">
        <el-input
          v-model="ruleForm.length"
          size="small"
          style="width: 240px"
          :placeholder="$t('DeviceManage.Geofence.Inputcharacte')"
          @input="changeLength"
          maxlength="7"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Geofence.Width')+'：'" prop="width" v-if="ruleForm.shape == 1">
        <el-input
          v-model="ruleForm.width"
          size="small"
          style="width: 240px"
          :placeholder="$t('DeviceManage.Geofence.Inputcharacte')"
          @input="changeLength"
          maxlength="7"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Geofence.Insideandout')+'：'">
        <el-select
          v-model="ruleForm.scope"
          size="small"
          style="width: 240px"
          :placeholder="$t('DeviceManage.Geofence.Insideout')"
        >
          <el-option
            v-for="(item, index) in about"
            :key="index"
            :label="$t(`DeviceManage.Geofence.RadiusInsideoutside.${item.label}`)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Geofence.Maptype')+'：'">
        <el-select
          v-model="ruleForm.mapType"
          size="small"
          style="width: 240px"
          :placeholder="$t('DeviceManage.Geofence.SelectMaptype')"
          @change="changeType"
        >
          <el-option
            v-for="(item, index) in mapTypeId"
            :key="index"
            :label="$t(`DeviceManage.Geofence.Atlas.${item.label}`)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.Geofence.Fencedarea')+'：'">
        <div
          v-show="ruleForm.mapType == 0"
          class="box-conent"
          style="width: 606px; height: 406px"
        >
          <div
            v-show="ruleForm.shape == 0"
            id="container"
            style="width: 606px; height: 406px"
          ></div>
          <div
            v-show="ruleForm.shape == 1"
            id="containerL"
            style="width: 606px; height: 406px"
          ></div>
          <div
            id="myPageTop"
            v-show="ruleForm.shape == 0"
            style="
              position: absolute;
              top: 5px;
              right: 10px;
              font-size: 14px;
              background: #ffffff;
              border-width: 1px;
              border-style: solid;
              border-image: initial;
              margin: 10px auto;
              padding: 6px;
            "
          >
            <table>
              <tr>
                <td>
                  <label>{{$t('DeviceManage.Geofence.Enterkeyword')}}</label>
                </td>
              </tr>
              <tr>
                <td>
                  <input @input="changInput" ref="inputs" id="tipinput" />
                  
                </td>
              </tr>
            </table>
          </div>
          <div
            id="myPageTop"
            v-show="ruleForm.shape == 1"
            style="
              position: absolute;
              top: 5px;
              right: 10px;
              font-size: 14px;
              background: #ffffff;
              border-width: 1px;
              border-style: solid;
              border-image: initial;
              margin: 10px auto;
              padding: 6px;
            "
          >
            <table>
              <tr>
                <td>
                  <label>{{$t('DeviceManage.Geofence.Enterkeyword')}}</label>
                </td>
              </tr>
              <tr>
                <td>
                  <input @input="changInput1" ref="inputs1" id="tipinput1" />
                  <p style="height: 30px; font-size: 12px" v-if="flagTest">
                    {{$t('DeviceManage.Geofence.Notfound')}}
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          v-show="ruleForm.mapType == 1"
          class="box-conent"
          style="width: 606px; height: 406px"
        >
          <div
            v-show="ruleForm.shape == 0"
            id="containers"
            style="width: 606px; height: 406px"
          ></div>
          <div
            v-show="ruleForm.shape == 1"
            id="containerK"
            style="width: 606px; height: 406px"
          ></div>
          <div
            id="myPageTop"
            v-show="ruleForm.shape == 0"
            style="
              position: absolute;
              top: 5px;
              right: 10px;
              font-size: 14px;
              background: #ffffff;
              border-width: 1px;
              border-style: solid;
              border-image: initial;
              margin: 10px auto;
              padding: 6px;
            "
          >
            <table>
              <tr>
                <td>
                  <label>{{$t('DeviceManage.Geofence.Enterkeyword')}}</label>
                </td>
              </tr>
              <tr>
                <td>
                  <input @input="changInput2" ref="inputs2" id="tipinputO" />
                  <p style="height: 30px; font-size: 12px" v-if="flagTest">
                    {{$t('DeviceManage.Geofence.Notfound')}}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div
            id="myPageTop"
            v-show="ruleForm.shape == 1"
            style="
              position: absolute;
              top: 5px;
              right: 10px;
              font-size: 14px;
              background: #ffffff;
              border-width: 1px;
              border-style: solid;
              border-image: initial;
              margin: 10px auto;
              padding: 6px;
            "
          >
            <table>
              <tr>
                <td>
                  <label>{{$t('DeviceManage.Geofence.Enterkeyword')}}</label>
                </td>
              </tr>
              <tr>
                <td>
                  <input @input="changInput3" ref="inputs3" id="tipinputK" />
                  <p style="height: 30px; font-size: 12px" v-if="flagTest">
                    {{$t('DeviceManage.Geofence.Notfound')}}
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="submitForm('ruleForm')"
          >{{$t("public.Save")}}</el-button
        >
      </el-form-item>
    </el-form>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import MapLoader from "@/assets/Amap/Amap.js"
import dialogInfo from "@/components/promptMessage/index";
export default {
  props: {
    addArray: {
      type: Object,
      default: () => {},
    },
    addArrayId: {
      type: String,
      default: "",
    },
  },
  components: {
    dialogInfo,
  },
  created() {
    MapLoader()
    this.IDS = this.addArrayId;
    console.log(this.addArray);
    if (this.addArray && this.addArray.id) {
      this.juxing = []
      this.ruleForm.name = this.addArray.name;
      this.ruleForm.timezone = this.addArray.timezone;
      this.ruleForm.valid = this.addArray.valid + "";
      this.ruleForm.start = this.addArray.start;
      this.ruleForm.end = this.addArray.end;
      this.ruleForm.timeStart = this.addArray.timeStart;
      this.ruleForm.timeEnd = this.addArray.timeEnd;
      this.ruleForm.longitude = this.addArray.longitude;
      this.ruleForm.latitude = this.addArray.latitude;
      this.ruleForm.length = this.addArray.length;
      this.ruleForm.width = this.addArray.width;
      this.radiCencer = [this.addArray.longitude, this.addArray.latitude];
      this.juxing = [this.addArray.longitude*1,this.addArray.latitude*1]
      this.ruleForm.mapType = this.addArray.mapType + "";
      this.ruleForm.radius = this.addArray.radius;
      this.$set(this.ruleForm, "shape", this.addArray.shape + "");
      this.$set(this.ruleForm, "scope", this.addArray.scope + "");
    }
    this.getNowTime();
    this.getNowTime1();
  },
  data() {
    let self = this;
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error("经度不支持特殊字符"));
      } else {
        callback();
      }
    };
    var validateInput1 = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error("纬度不支持特殊字符"));
      } else {
        callback();
      }
    };
    var validateInput2 = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error("半径不支持特殊字符"));
      } else {
        callback();
      }
    };
    var validateInput3 = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error("长不支持特殊字符"));
      } else {
        callback();
      }
    };
    var validateInput4 = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error("宽不支持特殊字符"));
      } else {
        callback();
      }
    };
    return {
      IDS: [""],
      flagTest: false,
      geoCoordMap: [
        { name: "海门市", value: "121.15, 31.89" },
        { name: "鄂尔多斯市", value: "109.781327, 39.608266" },
        { name: "招远市", value: "120.38, 37.35" },
        { name: "舟山市", value: "122.207216, 29.985295" },
        { name: "齐齐哈尔市", value: "123.97, 47.33" },
        { name: "盐城市", value: "120.13, 33.38" },
        { name: "赤峰市", value: "118.87, 42.28" },
        { name: "青岛市", value: "120.33, 36.07" },
        { name: "乳山市", value: "121.52, 36.89" },
        { name: "金昌市", value: "102.188043, 38.520089" },
        { name: "泉州市", value: "118.58, 24.93" },
        { name: "莱西市", value: "120.53, 36.86" },
        { name: "日照市", value: "119.46, 35.42" },
        { name: "胶南市", value: "119.97, 35.88" },
        { name: "南通市", value: "121.05, 32.08" },
        { name: "拉萨市", value: "91.11, 29.97" },
        { name: "云浮市", value: "112.02, 22.93" },
        { name: "梅州市", value: "116.1, 24.55" },
        { name: "文登市", value: "122.05, 37.2" },
        { name: "上海市", value: "121.48, 31.22" },
        { name: "攀枝花市", value: "101.718637, 26.582347" },
        { name: "威海市", value: "122.1, 37.5" },
        { name: "承德市", value: "117.93, 40.97" },
        { name: "厦门市", value: "118.1, 24.46" },
        { name: "汕尾市", value: "115.375279, 22.786211" },
        { name: "潮州市", value: "116.63, 23.68" },
        { name: "丹东市", value: "124.37, 40.13" },
        { name: "太仓市", value: "121.1, 31.45" },
        { name: "曲靖市", value: "103.79, 25.51" },
        { name: "烟台市", value: "121.39, 37.52" },
        { name: "福州市", value: "119.3, 26.08" },
        { name: "瓦房店市", value: "121.979603, 39.627114" },
        { name: "即墨市", value: "120.45, 36.38" },
        { name: "抚顺市", value: "123.97, 41.97" },
        { name: "玉溪市", value: "102.52, 24.35" },
        { name: "张家口市", value: "114.87, 40.82" },
        { name: "阳泉市", value: "113.57, 37.85" },
        { name: "莱州市", value: "119.942327, 37.177017" },
        { name: "湖州市", value: "120.1, 30.86" },
        { name: "汕头市", value: "116.69, 23.39" },
        { name: "昆山市", value: "120.95, 31.39" },
        { name: "宁波市", value: "121.56, 29.86" },
        { name: "湛江市", value: "110.359377, 21.270708" },
        { name: "揭阳市", value: "116.35, 23.55" },
        { name: "荣成市", value: "122.41, 37.16" },
        { name: "连云港市", value: "119.16, 34.59" },
        { name: "葫芦岛市", value: "120.836932, 40.711052" },
        { name: "常熟市", value: "120.74, 31.64" },
        { name: "东莞市", value: "113.75, 23.04" },
        { name: "河源市", value: "114.68, 23.73" },
        { name: "淮安市", value: "119.15, 33.5" },
        { name: "泰州市", value: "119.9, 32.49" },
        { name: "南宁市", value: "108.33, 22.84" },
        { name: "营口市", value: "122.18, 40.65" },
        { name: "惠州市", value: "114.4, 23.09" },
        { name: "江阴市", value: "120.26, 31.91" },
        { name: "蓬莱市", value: "120.75, 37.8" },
        { name: "韶关市", value: "113.62, 24.84" },
        { name: "嘉峪关市", value: "98.289152, 39.77313" },
        { name: "广州市", value: "113.23, 23.16" },
        { name: "延安市", value: "109.47, 36.6" },
        { name: "太原市", value: "112.53, 37.87" },
        { name: "清远市", value: "113.01, 23.7" },
        { name: "中山市", value: "113.38, 22.52" },
        { name: "昆明市", value: "102.73, 25.04" },
        { name: "寿光市", value: "118.73, 36.86" },
        { name: "盘锦市", value: "122.070714, 41.119997" },
        { name: "长治市", value: "113.08, 36.18" },
        { name: "深圳市", value: "114.07, 22.62" },
        { name: "珠海市", value: "113.52, 22.3" },
        { name: "宿迁市", value: "118.3, 33.96" },
        { name: "咸阳市", value: "108.72, 34.36" },
        { name: "铜川市", value: "109.11, 35.09" },
        { name: "平度市", value: "119.97, 36.77" },
        { name: "佛山市", value: "113.11, 23.05" },
        { name: "海口市", value: "110.35, 20.02" },
        { name: "江门市", value: "113.06, 22.61" },
        { name: "章丘市", value: "117.53, 36.72" },
        { name: "肇庆市", value: "112.44, 23.05" },
        { name: "大连市", value: "121.62, 38.92" },
        { name: "临汾市", value: "111.5, 36.08" },
        { name: "吴江市", value: "120.63, 31.16" },
        { name: "石嘴山市", value: "106.39, 39.04" },
        { name: "沈阳市", value: "123.38, 41.8" },
        { name: "苏州市", value: "120.62, 31.32" },
        { name: "茂名市", value: "110.88, 21.68" },
        { name: "嘉兴市", value: "120.76, 30.77" },
        { name: "长春市", value: "125.35, 43.88" },
        { name: "胶州市", value: "120.03336, 36.264622" },
        { name: "银川市", value: "106.27, 38.47" },
        { name: "张家港市", value: "120.555821, 31.875428" },
        { name: "三门峡市", value: "111.19, 34.76" },
        { name: "锦州市", value: "121.15, 41.13" },
        { name: "南昌市", value: "115.89, 28.68" },
        { name: "柳州市", value: "109.4, 24.33" },
        { name: "三亚市", value: "109.511909, 18.252847" },
        { name: "自贡市", value: "104.778442, 29.33903" },
        { name: "吉林市", value: "126.57, 43.87" },
        { name: "阳江市", value: "111.95, 21.85" },
        { name: "泸州市", value: "105.39, 28.91" },
        { name: "西宁市", value: "101.74, 36.56" },
        { name: "宜宾市", value: "104.56, 29.77" },
        { name: "呼和浩特市", value: "111.65, 40.82" },
        { name: "成都市", value: "104.06, 30.67" },
        { name: "大同市", value: "113.3, 40.12" },
        { name: "镇江市", value: "119.44, 32.2" },
        { name: "桂林市", value: "110.28, 25.29" },
        { name: "张家界市", value: "110.479191, 29.117096" },
        { name: "宜兴市", value: "119.82, 31.36" },
        { name: "北海市", value: "109.12, 21.49" },
        { name: "西安市", value: "108.95, 34.27" },
        { name: "金坛市", value: "119.56, 31.74" },
        { name: "东营市", value: "118.49, 37.46" },
        { name: "牡丹江市", value: "129.58, 44.6" },
        { name: "遵义市", value: "106.9, 27.7" },
        { name: "绍兴市", value: "120.58, 30.01" },
        { name: "扬州市", value: "119.42, 32.39" },
        { name: "常州市", value: "119.95, 31.79" },
        { name: "潍坊市", value: "119.1, 36.62" },
        { name: "重庆市", value: "106.54, 29.59" },
        { name: "台州市", value: "121.420757, 28.656386" },
        { name: "南京市", value: "118.78, 32.04" },
        { name: "滨州市", value: "118.03, 37.36" },
        { name: "贵阳市", value: "106.71, 26.57" },
        { name: "无锡市", value: "120.29, 31.59" },
        { name: "本溪市", value: "123.73, 41.3" },
        { name: "克拉玛依市", value: "84.77, 45.59" },
        { name: "渭南市", value: "109.5, 34.52" },
        { name: "马鞍山市", value: "118.48, 31.56" },
        { name: "宝鸡市", value: "107.15, 34.38" },
        { name: "焦作市", value: "113.21, 35.24" },
        { name: "句容市", value: "119.16, 31.95" },
        { name: "北京市", value: "116.398551, 39.907602" },
        { name: "徐州市", value: "117.2, 34.26" },
        { name: "衡水市", value: "115.72, 37.72" },
        { name: "包头市", value: "110, 40.5" },
        { name: "绵阳市", value: "104.73, 31.48" },
        { name: "乌鲁木齐市", value: "87.68, 43.77" },
        { name: "枣庄市", value: "117.57, 34.86" },
        { name: "杭州市", value: "120.19, 30.26" },
        { name: "淄博市", value: "118.05, 36.78" },
        { name: "鞍山市", value: "122.85, 41.12" },
        { name: "溧阳市", value: "119.48, 31.43" },
        { name: "库尔勒市", value: "86.06, 41.68" },
        { name: "安阳市", value: "114.35, 36.1" },
        { name: "开封市", value: "114.35, 34.79" },
        { name: "济南市", value: "117, 36.6" },
        { name: "德阳市", value: "104.37, 31.13" },
        { name: "温州市", value: "120.65, 28.01" },
        { name: "九江市", value: "115.97, 29.71" },
        { name: "邯郸市", value: "114.47, 36.6" },
        { name: "临安市", value: "119.72, 30.23" },
        { name: "兰州市", value: "103.73, 36.03" },
        { name: "沧州市", value: "116.83, 38.33" },
        { name: "临沂市", value: "118.35, 35.05" },
        { name: "南充市", value: "106.110698, 30.837793" },
        { name: "天津市", value: "117.2, 39.13" },
        { name: "富阳市", value: "119.95, 30.07" },
        { name: "泰安市", value: "117.13, 36.18" },
        { name: "诸暨市", value: "120.23, 29.71" },
        { name: "郑州市", value: "113.65, 34.76" },
        { name: "哈尔滨市", value: "126.63, 45.75" },
        { name: "聊城市", value: "115.97, 36.45" },
        { name: "芜湖市", value: "118.38, 31.33" },
        { name: "唐山市", value: "118.02, 39.63" },
        { name: "平顶山市", value: "113.29, 33.75" },
        { name: "邢台市", value: "114.48, 37.05" },
        { name: "德州市", value: "116.29, 37.45" },
        { name: "济宁市", value: "116.59, 35.38" },
        { name: "荆州市", value: "112.239741, 30.335165" },
        { name: "宜昌市", value: "111.3, 30.7" },
        { name: "义乌市", value: "120.06, 29.32" },
        { name: "丽水市", value: "119.92, 28.45" },
        { name: "洛阳市", value: "112.44, 34.7" },
        { name: "秦皇岛市", value: "119.57, 39.95" },
        { name: "株洲市", value: "113.16, 27.83" },
        { name: "石家庄市", value: "114.48, 38.03" },
        { name: "莱芜市", value: "117.67, 36.19" },
        { name: "常德市", value: "111.69, 29.05" },
        { name: "保定市", value: "115.48, 38.85" },
        { name: "湘潭市", value: "112.91, 27.87" },
        { name: "金华市", value: "119.64, 29.12" },
        { name: "岳阳市", value: "113.09, 29.37" },
        { name: "长沙市", value: "113, 28.21" },
        { name: "衢州市", value: "118.88, 28.97" },
        { name: "廊坊市", value: "116.7, 39.53" },
        { name: "菏泽市", value: "115.480656, 35.23375" },
        { name: "合肥市", value: "117.27, 31.86" },
        { name: "武汉市", value: "114.31, 30.52" },
        { name: "大庆市", value: "125.03, 46.58" },
      ],
      geoCoordMap1: [
        { name: "台湾省", value: "121.5135, 25.0308" },
        { name: "黑龙江省", value: "127.9688, 45.368" },
        { name: "内蒙古省", value: "110.3467, 41.4899" },
        { name: "吉林省", value: "125.8154, 44.2584" },
        { name: "辽宁省", value: "123.1238, 42.1216" },
        { name: "河北省", value: "114.4995, 38.1006" },
        { name: "天津省", value: "117.4219, 39.4189" },
        { name: "山西省", value: "112.3352, 37.9413" },
        { name: "陕西省", value: "109.1162, 34.2004" },
        { name: "甘肃省", value: "103.5901, 36.3043" },
        { name: "宁夏省", value: "106.3586, 38.1775" },
        { name: "青海省", value: "101.4038, 36.8207" },
        { name: "新疆省", value: "87.9236, 43.5883" },
        { name: "西藏省", value: "91.11, 29.97" },
        { name: "四川省", value: "103.9526, 30.7617" },
        { name: "山东省", value: "117.1582, 36.8701" },
        { name: "河南省", value: "113.4668, 34.6234" },
        { name: "江苏省", value: "118.8062, 31.9208" },
        { name: "安徽省", value: "117.29, 32.0581" },
        { name: "湖北省", value: "114.3896, 30.6628" },
        { name: "浙江省", value: "119.5313, 29.8773" },
        { name: "福建省", value: "119.4543, 25.9222" },
        { name: "江西省", value: "116.0046, 28.6633" },
        { name: "湖南省", value: "113.0823, 28.2568" },
        { name: "贵州省", value: "106.6992, 26.7682" },
        { name: "云南省", value: "102.9199, 25.4663" },
        { name: "广东省", value: "113.12244, 23.009505" },
        { name: "广西省", value: "108.479, 23.1152" },
        { name: "海南省", value: "110.3893, 19.8516" },
        { name: "上海省", value: "121.4648, 31.2891" },
      ],
      placeSearch: null,
      input: "",
      juxing: [116.387175, 39.876405],
      // 图形
      graphical: [
        { value: "0", label: "圆形" },
        { value: "1", label: "矩形" },
      ],
      //   范围内外
      about: [
        { value: "0", label: "内部" },
        { value: "1", label: "外部" },
      ],
      //   地图类型
      mapTypeId: [
        { value: "0", label: "普通" },
        { value: "1", label: "卫星" },
      ],
      visibaelFlag: false,
      titleInfo: "",
      ruleForm: {
        name: "",
        longitude: "116.405285",
        latitude: "39.904989",
        shape: "0",
        scope: "0",
        mapType: "0",
        radius: 100,
        length: "100",
        width: "50",
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, max: 20, message: "最长输入20个字符", trigger: "blur" },
        ],
        // 经度
        longitude: [
          { required: true, message: "请输入经度", trigger: "blur" },
          { validator: validateInput, trigger: ["blur", "change"] },
        ],
        // 纬度  /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
        latitude: [
          { required: true, message: "请输入纬度", trigger: "blur" },
          { validator: validateInput1, trigger: ["blur", "change"] },
        ],
        radius: [
          { required: true, message: "请输入半径", trigger: "blur" },
          { validator: validateInput2, trigger: ["blur", "change"] },
        ],
        length: [
          { required: true, message: "请输入长", trigger: "blur" },
          { validator: validateInput3, trigger: ["blur", "change"] },
        ],
        width: [
          { required: true, message: "请输入宽", trigger: "blur" },
          { validator: validateInput4, trigger: ["blur", "change"] },
        ],
      },
      flagStatus: false,
      center: [116.405285, 39.904989],
      zoom: 15,
      label: {
        content: "自定义标题",
        offset: [10, 12],
      },
      radiCencer: [116.405285, 39.904989],
      events: {
        click(e) {
          console.log(e);
        },
      },
      flag: 1,
    };
  },
  mounted: function () {
    setTimeout(()=>{
      this.init();
      this.rectangleEditor();
      this.initMapStart();
      this.initMapLediotir();
    },1000)
  },
  methods: {
    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*（）-——|{}【】‘；：”“'。，、？]‘'";
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    changeType(val) {
      if (val == 1) {
      }
    },
    // 初始化地图
    initMap() {
      if (this.ruleForm.shape == 0) {
        this.init();
      }
      if (this.ruleForm.shape == 1) {
        this.rectangleEditor();
      }
    },
    //卫星圆形
    initMapStart: function () {
      var that = this;
      var map = new AMap.Map("containers", {
        center: [this.ruleForm.longitude, this.ruleForm.latitude],
        layers: [
          // 卫星
          new AMap.TileLayer.Satellite(),
          // 路网
          new AMap.TileLayer.RoadNet(),
        ],
        zoom: 17,
      });
      // this.$refs.panel.style = "display:none;"
      var circle = new AMap.Circle({
        center: this.radiCencer,
        radius: this.ruleForm.radius, //半径
        borderWeight: 3,
        strokeColor: "red",
        strokeOpacity: 1,
        strokeWeight: 3,
        strokeOpacity: 0.5,
        fillOpacity: 0.2,
        strokeStyle: "solid",
        strokeDasharray: [10, 10],
        // 线样式还支持 'dashed'
        fillColor: "red",
        zIndex: 50,
      });
      circle.setMap(map);
      // 缩放地图到合适的视野级别
      map.setFitView([circle]);
      var circleEditor = new AMap.CircleEditor(map, circle);
      circleEditor.on("adjust", (e) => {
        this.$set(this.ruleForm, "radius", e.radius);
        // this.ruleForm.radius=event.radius
      });
      circleEditor.on("move", (e) => {
        console.log(e);
        this.ruleForm.longitude = e.lnglat.lng;
        this.ruleForm.latitude = e.lnglat.lat;
        this.radiCencer = [e.lnglat.lng, e.lnglat.lat];
      });
      circleEditor.open();
      AMap.event.addListener(map, "click", (e) => {
        console.log(e, "click");
        this.ruleForm.longitude = e.lnglat.lng;
        this.ruleForm.latitude = e.lnglat.lat;
        this.radiCencer = [e.lnglat.lng, e.lnglat.lat];
        this.initMapStart();
      });
      var autoOptions = {
        input: "tipinputO",
      };
      var auto = new AMap.Autocomplete(autoOptions);
      var placeSearch = new AMap.PlaceSearch({
        city: "010", // 兴趣点城市
        map: map,
      }); //构造地点查询类
      AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
      function select(e) {
        if (e.poi.location) {
          that.radiCencer = [e.poi.location.lng, e.poi.location.lat];
          that.initMapStart();
        }
        that.geoCoordMap.forEach((item, index) => {
          if (e.poi.name == item.name) {
            let arr = item.value.split(",");
            that.radiCencer = [arr[0] * 1, arr[1] * 1];
            that.initMapStart();
          }
        });
        that.geoCoordMap1.forEach((item, index) => {
          if (e.poi.name == item.name) {
            let arr = item.value.split(",");
            that.radiCencer = [arr[0] * 1, arr[1] * 1];
            that.initMapStart();
          }
        });
      }
    },
    // 卫星矩形
    initMapLediotir: function () {
      var that = this;
      var map = new AMap.Map("containerK", {
        center: this.radiCencer,
        layers: [
          // 卫星
          new AMap.TileLayer.Satellite(),
          // 路网
          new AMap.TileLayer.RoadNet(),
        ],
        zoom: 17,
      });
      // 下
      var southWest = this.juxing;
      // s上
      var northEast = new AMap.LngLat(
        this.juxing[0] + 0.00001171 * this.ruleForm.length,
        this.juxing[1] + 0.00000898 * this.ruleForm.width
      );
      var bounds = new AMap.Bounds(southWest, northEast);
      var rectangle = new AMap.Rectangle({
        bounds: bounds,
        strokeColor: "red",
        strokeWeight: 3,
        strokeOpacity: 0.5,
        strokeDasharray: [100, 20],
        // strokeStyle还支持 solid
        strokeStyle: "solid",
        fillColor: "red",
        fillOpacity: 0.2,
        cursor: "pointer",
        zIndex: 100,
      });
      map.add(rectangle);
      rectangle.setMap(map);
      // map.setBounds(bounds);
      // 缩放地图到合适的视野级别
      map.setFitView([rectangle]);
      var rectangleEditor = new AMap.RectangleEditor(map, rectangle);
      rectangleEditor.on("adjust", function (e) {
        that.$set(that.ruleForm,'longitude',e.Td.wc.lng)
        that.$set(that.ruleForm,'latitude',e.Td.wc.lat)
        that.juxing=[e.Td.wc.lng,e.Td.wc.lat]
        let number = (e.Td.oc.lng-e.Td.wc.lng).toFixed(6) *100 /0.0012
        let sumber = (e.Td.oc.lat - e.Td.wc.lat).toFixed(6) *100 /0.0009
        that.ruleForm.length = number.toFixed()
        that.ruleForm.width = sumber.toFixed()
      });
      rectangleEditor.open();
      AMap.event.addListener(map, "click", (e) => {
        this.ruleForm.longitude = e.lnglat.lng;
        this.ruleForm.latitude = e.lnglat.lat;
        this.juxing = [e.lnglat.lng, e.lnglat.lat];
        this.initMapLediotir();
      });
      var autoOptions = {
        input: "tipinputK",
      };
      var auto = new AMap.Autocomplete(autoOptions);
      var placeSearch = new AMap.PlaceSearch({
        city: "010", // 兴趣点城市
        map: map,
      }); //构造地点查询类
      AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
      function select(e) {
        if (e.poi.location) {
          that.juxing = [e.poi.location.lng, e.poi.location.lat];
          that.initMapLediotir();
        }
        that.geoCoordMap.forEach((item, index) => {
          if (e.poi.name == item.name) {
            let arr = item.value.split(",");
            that.juxing = [arr[0] * 1, arr[1] * 1];
            that.initMapLediotir();
          }
        });
        that.geoCoordMap1.forEach((item, index) => {
          if (e.poi.name == item.name) {
            let arr = item.value.split(",");
            that.juxing = [arr[0] * 1, arr[1] * 1];
            that.initMapLediotir();
          }
        });
      }
    },
    // 圆形
    init: function () {
      var that = this;
      //地图加载
      var map = new AMap.Map("container", {
        center: this.radiCencer,
        resizeEnable: true,
        zoom: 17,
      });
      // this.$refs.panel.style = "display:none;"
      var circle = new AMap.Circle({
        center: [this.ruleForm.longitude, this.ruleForm.latitude],
        radius: this.ruleForm.radius, //半径
        borderWeight: 3,
        strokeColor: "red",
        strokeOpacity: 1,
        strokeWeight: 3,
        strokeOpacity: 0.5,
        fillOpacity: 0.2,
        strokeStyle: "solid",
        strokeDasharray: [10, 10],
        // 线样式还支持 'dashed'
        fillColor: "red",
        zIndex: 50,
      });
      circle.setMap(map);
      // 缩放地图到合适的视野级别
      map.setFitView([circle]);
      var circleEditor = new AMap.CircleEditor(map, circle);
      circleEditor.on("adjust", (e) => {
        this.$set(this.ruleForm,"radius",e.radius);
        // this.ruleForm.radius=event.radius
      });
      circleEditor.on("move", (e) => {
        this.$nextTick(() => {
          this.ruleForm.longitude = e.lnglat.lng;
          this.ruleForm.latitude = e.lnglat.lat;
        });
        this.radiCencer = [e.lnglat.lng, e.lnglat.lat];
      });
      circleEditor.open();
      AMap.event.addListener(map, "click", (e) => {
        console.log(e, "click");
        this.ruleForm.longitude = e.lnglat.lng;
        this.ruleForm.latitude = e.lnglat.lat;
        this.radiCencer = [e.lnglat.lng, e.lnglat.lat];
        this.init();
      });
      var autoOptions = {
        input: "tipinput",
      };
      var auto = new AMap.Autocomplete(autoOptions);
      console.log(auto);
      var placeSearch = new AMap.PlaceSearch({
        city: "010", // 兴趣点城市
        map: map,
      }); //构造地点查询类
      AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
      function select(e) {
        if (e.poi.location) {
          that.ruleForm.longitude = e.poi.location.lng;
          that.ruleForm.latitude = e.poi.location.lat;
          that.radiCencer = [e.poi.location.lng, e.poi.location.lat];
          that.init();
        }
        that.geoCoordMap.forEach((item, index) => {
          if (e.poi.name == item.name) {
            let arr = item.value.split(",");
            that.radiCencer = [arr[0] * 1, arr[1] * 1];
            that.ruleForm.longitude = arr[0] * 1;
            that.ruleForm.latitude = arr[1] * 1;
            that.init();
          }
        });
        that.geoCoordMap1.forEach((item, index) => {
          if (e.poi.name == item.name) {
            let arr = item.value.split(",");
            that.radiCencer = [arr[0] * 1, arr[1] * 1];
            that.ruleForm.longitude = arr[0] * 1;
            that.ruleForm.latitude = arr[1] * 1;
            that.init();
          }
        });
      }
      //输入提示
    },
    // 矩形
    rectangleEditor: function () {
      var that = this;
      var map = new AMap.Map("containerL", {
        center: this.juxing,
        zoom: 17,
        resizeEnable: true,
      });
      // 下
      var southWest = this.juxing;
      // s上
      var northEast = new AMap.LngLat(
        this.juxing[0] + 0.00001171 * this.ruleForm.length,
        this.juxing[1] + 0.00000898 * this.ruleForm.width
      );
      var bounds = new AMap.Bounds(southWest, northEast);
      var rectangle = new AMap.Rectangle({
        bounds: bounds,
        strokeColor: "red",
        strokeWeight: 3,
        strokeOpacity: 0.5,
        strokeDasharray: [100, 20],
        // strokeStyle还支持 solid
        strokeStyle: "solid",
        fillColor: "red",
        fillOpacity: 0.2,
        cursor: "pointer",
        zIndex: 50,
      });
      map.add(rectangle);
      rectangle.setMap(map);
      // map.setBounds(bounds);
      // 缩放地图到合适的视野级别
      map.setFitView([rectangle]);
      var rectangleEditor = new AMap.RectangleEditor(map, rectangle);
      rectangleEditor.on("adjust", function (e) {
          that.$set(that.ruleForm,'longitude',e.Td.wc.lng)
          that.$set(that.ruleForm,'latitude',e.Td.wc.lat)
          that.juxing=[e.Td.wc.lng,e.Td.wc.lat]
          let number = (e.Td.oc.lng-e.Td.wc.lng).toFixed(6) *100 /0.0012 //经度
          let sumber = (e.Td.oc.lat - e.Td.wc.lat).toFixed(6) *100 /0.0009 //维度
          that.ruleForm.length = number.toFixed()
          that.ruleForm.width = sumber.toFixed()
      });
      rectangleEditor.open();
      AMap.event.addListener(map, "click", (e) => {
        this.ruleForm.longitude = e.lnglat.lng;
        this.ruleForm.latitude = e.lnglat.lat;
        this.juxing = [e.lnglat.lng, e.lnglat.lat];
        console.log(this.juxing);
        this.rectangleEditor();
      });
      var autoOptions = {
        input: "tipinput1",
      };
      var auto = new AMap.Autocomplete(autoOptions);
      var placeSearch = new AMap.PlaceSearch({
        city: "010", // 兴趣点城市
        map: map,
      }); //构造地点查询类
      AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
      function select(e) {
        if (e.poi.location) {
          that.juxing = [e.poi.location.lng, e.poi.location.lat];
          that.rectangleEditor();
        }
        that.geoCoordMap.forEach((item, index) => {
          if (e.poi.name == item.name) {
            let arr = item.value.split(",");
            that.juxing = [arr[0] * 1, arr[1] * 1];
            that.rectangleEditor();
          }
        });
        that.geoCoordMap1.forEach((item, index) => {
          if (e.poi.name == item.name) {
            let arr = item.value.split(",");
            that.juxing = [arr[0] * 1, arr[1] * 1];
            that.rectangleEditor();
          }
        });
      }
    },
    changInput(val) {
      console.log(this.$refs.inputs.value);
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/.test(this.$refs.inputs.value)) {
        this.flagTest = true;
      }
      if (this.$refs.inputs.value == "") {
        this.flagTest = false;
      }
    },
    changInput1(val) {
      console.log(this.$refs.inputs1.value);
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/.test(this.$refs.inputs1.value)) {
        this.flagTest = true;
      }
      if (this.$refs.inputs1.value == "") {
        this.flagTest = false;
      }
    },
    changInput2(val) {
      console.log(this.$refs.inputs2.value);
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/.test(this.$refs.inputs2.value)) {
        this.flagTest = true;
      }
      if (this.$refs.inputs2.value == "") {
        this.flagTest = false;
      }
    },
    changInput3(val) {
      console.log(this.$refs.inputs3.value);
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/.test(this.$refs.inputs3.value)) {
        this.flagTest = true;
      }
      if (this.$refs.inputs3.value == "") {
        this.flagTest = false;
      }
    },
    changeText() {},
    changeRadius() {
      this.init();
      this.initMapStart();
    },
    changeLength() {
      this.rectangleEditor();
    },
    getNowTime() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      this.$set(this.ruleForm, "start", defaultDate);
    },
    getNowTime1() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 2;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      this.$set(this.ruleForm, "end", defaultDate);
    },
    changeEvent(val) {
      if (val == 1) {
        this.flagStatus = true;
      } else {
        this.flagStatus = false;
      }
    },
    async save() {
      if (this.IDS) {
        console.log(this.IDS, "-嘿哈嘿-");
        let params = {
          // ids: ["10"],
          ids: [this.IDS + ""],
          name: this.ruleForm.name,
          scope: this.ruleForm.scope,
          radius: this.ruleForm.radius,
          length: this.ruleForm.length,
          width: this.ruleForm.width,
          shape: this.ruleForm.shape,
          mapType: this.ruleForm.mapType,
          latitude: this.ruleForm.latitude,
          longitude: this.ruleForm.longitude,
        };
        console.log(params, "---保存编辑");
        const res = await this.$axios.post("/httpServe/geofence/save", params, true);
        if (res.data == "-1,修改地理围栏数据失败,名称已存在") {
          // this.titleInfo = "保存失败，名称已被使用";
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Geofence.NameAlreadyhave'),
            type: "error",
            offset: 100,
          });
        } else {
          // this.titleInfo = "保存成功";
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('public.SuccessfullySaved'),
            type: "success",
            offset: 100,
          });
          this.ruleForm = {};
          this.flag = 1;
          this.$emit("sunline", this.flag);
        }
        // this.ruleForm = {};
        // this.flag = 1;
      } else {
        let params = {
          // ids: ["10"],
          name: this.ruleForm.name,
          scope: this.ruleForm.scope,
          radius: this.ruleForm.radius,
          length: this.ruleForm.length,
          width: this.ruleForm.width,
          shape: this.ruleForm.shape,
          mapType: this.ruleForm.mapType,
          // coordinates: "test",
          latitude: this.ruleForm.latitude,
          longitude: this.ruleForm.longitude,
        };

        if (this.ruleForm.shape == 1) {
          params.longitude = this.juxing[0];
          params.latitude = this.juxing[1];
          params.coordinates = [
            {
              latitude: this.juxing[1], //1-4一样
              longitude: this.juxing[0], // 1-2一样
            },
            {
              latitude: this.juxing[1] + 0.00000898 * this.ruleForm.width, //2-3一样
              longitude: this.juxing[0], // 1-2一样
            },
            {
              latitude: this.juxing[1] + 0.00000898 * this.ruleForm.width, //2-3一样
              longitude: this.juxing[0] + 0.00001171 * this.ruleForm.length, //3-4一样
            },
            {
              latitude: this.juxing[1], //1-4一样
              longitude: this.juxing[0] + 0.00001171 * this.ruleForm.length, //3-4一样
            },
          ];
        }
        console.log(params);
        const res = await this.$axios.post("/httpServe/geofence/save", params, true);
        if (res.data == -1) {
          // this.titleInfo = "保存失败，名称已被使用";
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('DeviceManage.Geofence.NameAlreadyhave'),
            type: "error",
            offset: 100,
          });
        } else {
          // this.titleInfo = "保存成功";
          // this.visibaelFlag = true;
          // this.$message({
          //   message: this.$t('public.SuccessfullySaved'),
          //   type: "success",
          //   offset: 100,
          // });
          this.ruleForm = {};
          this.flag = 1;
          this.$emit("sunline", this.flag);
        }
        // this.ruleForm = {};
        // this.titleInfo = "保存成功";
        // this.visibaelFlag = true;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // this.save();
        if (valid) {
          this.save();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$emit("")
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    determine() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mapBox{
  width:100%;
  height:100%;
}
::v-deep .el-form-item {
  margin-bottom: 14px !important;
}
::v-deep .amap-maptypecontrol {
  // display: none;
}
::v-deep .amap-icon img {
  width: 10px;
  height: 10px;
}
.search-box {
  margin-top: 6px;
  width: 400px;
}
.search-box input {
  padding: 0 15px;
  width: 100%;
  height: 32px;
  line-height: 32px;
  color: #606266;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.search-box input:focus {
  border-color: #409eff;
  outline: 0;
}
.search-box input::-webkit-input-placeholder {
  color: #c0c4cc;
}
.tip-box {
  width: 400px;
  max-height: 400px;
  position: absolute;
  top: 72px;
  // z-index: 10000;
  overflow-y: auto;
  background-color: #fff;
}
</style>
<style>
.amap-ui-poi-picker-sugg,
.amap_lib_placeSearch {
  border: 1px solid #eee;
  border-radius: 4px;
}
.amap-box {
  width: 606px;
  height: 406px;
}
.box-conent {
  position: relative;
}
.inp {
  position: absolute;
  top: 10px;
  right: 10px;
}
/* #panel {
  position: absolute;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 48px;
  right: 10px;
  width: 200px;
  height: 300px;
  border-bottom: solid 1px silver;
  z-index: 99999999;
  font-size: 12px;
} */
</style>
