<template>
  <div class="zrgj">
    <!-- 地理围栏 -->
    <navi-gation style="font-size: 16px" />
    <div class="box" v-if="flag == 1" style="padding-right: 12px">
    <el-row :gutter="3">
      <el-col :span="3">
          <el-input v-model="queryObj.name" size="small" placeholder="请输入名称" style="width:100%" @keyup.enter.native="searchList"></el-input>
      </el-col>
      <el-col :span="4">
            <el-button size="small" type="primary" @click="searchList">查询</el-button>
      </el-col>
    </el-row>
      <div class="center">
        <el-button type="primary" style="border-radius: 3px 0px 0px 3px;margin: 0px 0px 0px 0px;"  @click="isflag" size="small">{{$t('public.New')}}</el-button>
        <el-button style="border-radius: 0px 3px 3px 0px;margin: 0px 0px 0px 0px;margin-left: 1px;"  @click="delArr(delarr)" :type="typeInfo" :disabled="!hasSeleted" size="small">{{$t('public.Delete')}}</el-button>
      </div>
      <!-- 列表 -->
      <div style="position: relative">
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" ref="multipleTable" :row-key="getRowKeys" :data="tableData" lazy size="mini" stripe class="tableClass" tooltip-effect="dark" style="width: 100%" :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }" @sort-change="handleSortChange" @selection-change="delobj">

          <el-table-column type="selection" width="60" align="center" :reserve-selection="true">
          </el-table-column>
          <!-- <el-table-column prop="id" label="ID" width="120"> </el-table-column> -->
          <el-table-column prop="name" :label="$t('DeviceManage.Geofence.Name')" width="120" :show-overflow-tooltip="true" sortable="custom">
          </el-table-column>
          <el-table-column prop="groupFullName" :label="$t('public.Section')" width="120" :show-overflow-tooltip="true" sortable="custom">
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">{{
                row.groupFullName.substr(groupFullNameShowByIndex)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="longitude" :label="$t('DeviceManage.Geofence.Latitudelongitude')" width="180" :show-overflow-tooltip="true" sortable="custom">
            <template v-slot="{ row }">
              {{row.longitude+','+row.latitude}}
            </template>
          </el-table-column>
          <el-table-column prop="shape" :label="$t('DeviceManage.Geofence.Graphical')" width="120" sortable="custom">
            <template v-slot="{ row }">
              <template v-if="row.shape == 0"> {{$t('DeviceManage.Geofence.Circular')}} </template>
              <template v-if="row.shape == 1"> {{$t('DeviceManage.Geofence.Rectangle')}} </template>
            </template>
          </el-table-column>
          <el-table-column prop="radius" :label="$t('DeviceManage.Geofence.Radius')" width="120" sortable="custom">
            <template v-slot="{ row }">
              <span v-if="row.shape == 0"> {{ row.radius }}{{$t('DeviceManage.Geofence.Meter')}} </span>
              <span v-if="row.shape == 1"> {{ row.length }},{{ row.width }}{{$t('DeviceManage.Geofence.Meter')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="scope" :label="$t('DeviceManage.Geofence.Insideandout')" width="120" sortable="custom">
            <template v-slot="{ row }">
              <template v-if="row.scope == 0"> {{$t('DeviceManage.Geofence.Interior')}} </template>
              <template v-if="row.scope == 1"> {{$t('DeviceManage.Geofence.Without')}} </template>
            </template>
          </el-table-column>
          <el-table-column prop="lastUpdateTime" :label="$t('public.ModificationTime')" width="200" :show-overflow-tooltip="true" sortable="custom">
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <span class="action_icon">
                <img @click="edit(scope.row)" :title="$t('public.Editor')" src="@/assets/icon_write.png" style="width: 15px; height: 15px; cursor: pointer" />
              </span>
              
              <span class="action_icon">
                <img :title="$t('public.Delete')" @click="del(scope.row.id)" src="@/assets/icon_del.png" style="width: 15px; height: 15px; cursor: pointer" />
              </span>
              
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <!-- v-on:handleChildGetList="handleParentGetList" -->
        <pagination :page="currentPage4" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
        <!-- <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="slot, sizes, prev, pager, next, jumper"
          :total="total"
>
        <span style="font-weight: 400; color: #565656; float: left"
          >共{{ total }}条记录</span
        >
        </el-pagination> -->
        <!-- //mouseLeave鼠标移出事件弹出框隐藏 -->
        <!-- <ul class="tableCheckBox" v-show="isCheckShow" @mouseleave="leave">
          <li @click="curSelection()">当前页全选</li> -->
          <!-- <li @click="toggleAllSelection()">所有页全选</li> -->
          <!-- <li @click="toggleSelection()">取消全选</li>
        </ul> -->
      </div>
    </div>
    <!-- 新增 gegoradd -->
    <gegoradd v-if="flag == 2" @sunline="sunline" :addArray="addArray" :addArrayId="addArrayId"></gegoradd>
    <!-- 保存成功的弹框 -->
    <dialog-info :dialogInfo="Successdialog" @handleClose="handleClose" @determine="determine" :titleInfo="seccess" :width="'240px'" />
  </div>
</template>
<script>
import pagination from '@/components/pagination/page'
import naviGation from '@/components/hearder/index'
import dialogInfo from '@/components/promptMessage/index'
import gegoradd from './components/gegoradd.vue'
export default {
  components: { pagination, naviGation, dialogInfo, gegoradd },

  data() {
    return {
      queryObj:{
        name:''
      },
      typeInfo: 'info',// 删除按钮默认灰色
      groupFullNameShowByIndex: '', //用户部门截取的下标
      addArrayId: '', //新增点击传过去组织树的id
      addArray: null,
      //   分页
      tableData: [],
      currentPage4: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      flag: 1,

      id: '',
      name: '',
      Successdialog: false, //控制弹出
      Sencond: 5, //设置初始倒计时
      isDisabled: false,
      time: null,
      seccess: '',
      delarr: [], //删除
      // 全选弹框
      isCheckShow: false,
      // 全选框双向绑定
      checked: false,
      // 校验
      name: '',
      memo: '',
      rules: {
        name: [
          { required: true, message: '请输入事件名称', trigger: 'blur' },
          { min: 1, max: 20, message: '最长输入20个字符', trigger: 'blur' }
        ],
        memo: [
          { required: true, message: '请输入事件描述', trigger: 'blur' },
          { min: 1, max: 200, message: '最长输入200个字符', trigger: 'blur' }
        ]
      },
      // 排序
      page: {
        orderColume: 'lastUpdateTime', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      }
    }
  },

  watch: {},
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length
    }
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    this.getDataList()
  },

  methods: {
    searchList() {
      this.currentPage4 = 1
      this.getDataList()
    },
    getRowKeys(row) {
      return row.id
    },

    //监听排序
    handleSortChange(column) {
      if (column.order === 'ascending') {
        this.page.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.page.orderRule = 'DESC'
      }
      this.page.orderColume = column.prop
      this.getDataList()
    },
    // 列表
    async getDataList() {
      var param = {
        name:this.queryObj.name,
        currentPage: this.currentPage4,
        pageSize: this.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule
        // pageNumber: this.currentPage4, //页数
        // rowNumber: this.pageSize, //行数
        // sort: "geo.lastUpdateTime desc", //排序, desc-倒序， asc-正序, 支持字段：r.name(框架名称), r.updateTime(修改时间)
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/geofence/getDataInfo',
        param,
        true
      )
      // const res = await this.$axios.post("/api/geofence/paginate.do", param, true);
      // console.log(res);
      this.tableData = res.data.content
      // this.tableData = res.data;
      console.log(res)
      // console.log(this.tableData);
      this.total = res.data.total
      // this.total = res.totalCount;
    },
    // 删除
    delobj(obj) {
      console.log(obj,'---------');
      this.delarr = obj
      this.typeInfo = 'primary'
      if (this.delarr.length == 0) {
        this.typeInfo = 'info'
      }
    },
    // 删除 
    delArr(obj) {
      var ids = []
      obj.forEach((item) => {
        ids.push(item.id)
      }) // 请求接口
      // console.log(ids);
      var parma = { ids: ids }
      // var parma = { action: "add", id: ids };

      this.$confirm(this.$t('DeviceManage.Geofence.FenceOK'),
        this.$t('public.PromptMessage'),
        {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/geofence/delete',
            parma,
            true
          )
          // const res = await this.$axios.post("/api/geofence/delete.do", parma, true);
          console.log(res, 'shan')
          if (res.code == 101) {
            console.log(res, '======')
            this.$message({
              message: this.$t('DeviceManage.Geofence.DeleteLose'),
              type: 'error',
              offset: 100
            })
            this.$refs.multipleTable.clearSelection()
          } else {
            // this.Successdialog = true
            // this.seccess = '删除成功'
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            })
            this.$refs.multipleTable.clearSelection()
            this.currentPage4 = 1
            this.getDataList()
            // this.time = setInterval(() => {
            //   this.Sencond -= 1;
            // }, 1000);
          }
        })
        .catch(() => {
          return false
        })
    },
    del(id) {
      // 请求接口
      var parma = { ids: [id] }

      this.$confirm(this.$t('DeviceManage.Geofence.FenceOK'),
        this.$t('public.PromptMessage'),
        {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$axios.post(
            '/httpServe/geofence/delete',
            parma,
            true
          )
          // const res = await this.$axios.post("/api/geofence/delete.do", parma, true);
          // console.log(res);
          if (res.code == 101) {
            this.$message({
              message: this.$t('DeviceManage.Geofence.DeleteLose'),
              type: 'error',
              offset: 100
            })
            this.$refs.multipleTable.clearSelection()
          } else {
            // this.Successdialog = true
            // this.seccess = '删除成功'
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            })
            // this.time = setInterval(() => {
            //   this.Sencond -= 1;
            // }, 1000);
            this.$refs.multipleTable.clearSelection()
            this.currentPage4 = 1
            this.getDataList()
          }
        })
        .catch(() => {
          return false
        })
    },
    sunline(data) {
      console.log(data, '--熙增data')
      if (data == 1) {
        this.getDataList()
        // this.Successdialog = true
        // this.seccess = '保存成功'
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
        this.queryObj.name=''
      }
      this.flag = data
      console.log(this.flag, '---fals')

      // var flag =1
      // this.$emit("sunline",flag)
    },
    handleClose() {
      this.Successdialog = false //提示消息  关闭
    },
    determine() {
      this.Successdialog = false //提示消息  关闭
    }, // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage4 = page
      this.pageSize = limit
      // this.queryObj.currentPage = page;
      // this.queryObj.pageSize = limit;
      // 调用查询方法
      this.getDataList()
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.getDataList()
    },
    // 新增
    isflag() {
      this.flag = 2
      this.addArray = null
      this.addArrayId = ''
    },
    // 编辑
    edit(val) {
      console.log(val, '编辑点击一行')
      // this.IDS = val.id
      this.addArray = val
      this.addArrayId = val.id + ''
      // this.geogredit={ ...val}
      // window.sessionStorage.setItem("id", id);
      this.flag = 2
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false
    },
    checkChange(selection, row) {
      selection.forEach((item) => {
        this.delarr.push(item.id)
      })
    },

    //当前页全选
    curSelection() {
      this.$refs.tableData.toggleAllSelection()
    },
    //所有页全选
    // toggleAllSelection() {
    //   this.$refs.tableData.toggleAllSelection();
    // },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.tableData.toggleRowSelection(row)
        })
      } else {
        this.$refs.tableData.clearSelection()
      }
      this.isCheckShow = true
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
.breadtitle {
  margin-left: 10px;
  border-bottom: 1px solid #cdd1dd;
  height: 40px;
  line-height: 40px;
  color: #6d7a82;
  font-size: 16px;
}
.zrgj {
  width: 100%;
  height: 100%;
}
.el-input1 {
  width: 200px;
  margin-left: 1px;
}
.btn {
  margin-left: 1px;
  background: #4787de;
  margin-right: 1px;
}
.searchbtn {
  margin-left: 4px;
}
.center {
  margin: 10px 0;
  justify-content: flex-start;
}
.save {
  margin: 4px 0 102px 200px;
}
.el-form {
  margin-top: 25px;
}
.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}
.box {
  margin-left: 10px;
  margin-top: 10px;
}
.el-input {
  width: 50%;
}
#text {
  resize: none;
}
span >>> .el-input > input {
  height: 24px;
}
.el-form-item__content {
  width: 78%;
  display: flex;
  margin: 0 !important;
}
// #success{
//   // height: 100px;
// }
//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 1%;
  z-index: 999;
  // background:rgba(255, 255, 255,.1)
  li {
    list-style: none;
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;
    div {
      display: inline-block;
    }
  }
  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}
.el-button + .el-button {
  margin-left: 0px;
}
.el-checkbox {
  margin-right: 10px;
}
.el-radio,
.el-radio__input {
  margin-left: 10px;
}
.el-checkbox,
.el-checkbox__input {
  margin-left: 3px;
}
.el-textarea__inner {
  resize: none;
}
.el-select .el-input {
  width: 120px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.el-checkbox:last-of-type {
  margin-left: 12px;
}
.el-form {
  width: 848px;
}
// .el-form-item {
//   margin-bottom: 5px;
// }
.el-form[data-v-42c2d864] {
  margin-top: 10px;
}
.el-form-item__content {
  width: 662px;
  display: flex;
  margin: 0 !important;
}
.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  //height: 100px;
  overflow: auto;
  margin-right: 12px;
}
// .center {
//   width: 100%;
// margin-left: 10px;
// margin-right: 10px;
// margin-top: 10px;
/* display: flex; */
/* justify-content: space-between; */
//   justify-content: flex-start;
// }
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  height: 14px;
  line-height: 20px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.el-form-item__content {
  line-height: 36px;
  position: relative;
  font-size: 14px;
}
.save[data-v-42c2d864] {
  margin: 21px 0 102px 200px !important;
  margin-bottom: 110px;
}
</style>
<style lang="scss">
.save[data-v-42c2d864] {
  margin: 21px 0 102px 200px !important;
  margin-bottom: 110px;
}
</style>